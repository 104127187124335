
import { gsap, Power2 } from 'gsap';

// Get the actual transform translateZ value
// const getTransformTranslateZValue = (el) => {
//   const style = window.getComputedStyle(el)
//   const matrix = new DOMMatrixReadOnly(style.transform)
//   return matrix.m43;
// }

/**
 * Animation function to be triggered continuously, automaticaly and on mousemove
 */
export const continuousAnimations = {
  1: function(slide, tilt, degreeRatio) {
      const radius = Math.sqrt(Math.pow(tilt.x,2) + Math.pow(tilt.y,2));
      const degree = (radius * degreeRatio);

      gsap.to("#" + slide.id + " .hero-slide__inner", 1, {transform:'rotate3d(' + tilt.x + ', ' + tilt.y + ', 0, ' + degree + 'deg)', ease:Power2.easeOut});
  },
  2: function(slide, tilt, degreeRatio) {
      const radius = Math.sqrt(Math.pow(tilt.x,2) + Math.pow(tilt.y,2));
      const degree = (radius * degreeRatio);

      gsap.to("#" + slide.id + " .hero-slide__inner", 1, {transform:'rotate3d(' + tilt.x + ', ' + tilt.y + ', 0, ' + degree + 'deg)', ease:Power2.easeOut});
  },
  3: function(slide, tilt, degreeRatio) {

  }
};

/**
 * Functions to generate GSAP timeslines for each slide and animation type
 */
export const introAnimations = {
  1: function(slide, container) {
      const timeline = gsap.timeline();

      // Animate the backgrounds
      timeline.from("#" + slide.id + " .hero-slide__image-bg", { scale: 2, duration: 2, ease:Power2.easeOut}, 0);
      timeline.from("#" + slide.id + " .hero-slide__image-bg-back", { scale: 3.25, duration: 2, ease:Power2.easeOut}, 0);
      timeline.from("#" + slide.id + " .hero-slide__image-bg, #" + slide.id + " .hero-slide__image-bg-back", { filter:'blur(0px)', duration: 2, ease:Power2.easeOut}, .5);

      timeline.from("#" + slide.id + " .hero-slide__image-pattern", { opacity: 0 , duration: 2, ease:Power2.easeOut}, 1);
      timeline.from("#" + slide.id + " .hero-slide__image-pattern", { scale: 1.3, filter:'blur(3px)', duration: 2, ease:Power2.easeOut}, 1);

      // Animate the title text
      const titleEl = container.querySelector("#" + slide.id + " .hero-slide__title")
      timeline.from(titleEl, { z: '+= 500', opacity: 0, duration: 2, ease:Power2.easeOut }, 1.2);

      timeline.from(titleEl.querySelector(".text__el1"), { opacity: 0, duration: .75,  ease:Power2.easeOut}, 1.1);
      timeline.from(titleEl.querySelector(".text__el1"), { x: -400, duration: 1.5, ease:Power2.easeOut}, 1.1);

      timeline.from(titleEl.querySelector(".text__el2"), { opacity: 0, duration: .75, ease:Power2.easeOut}, 1.75);
      timeline.from(titleEl.querySelector(".text__el2"), { x: 400, duration: 1.5, ease:Power2.easeOut}, 1.75);

      const titleDecoEl = titleEl.querySelector(".text-effect")
      timeline.from(titleDecoEl, { width: 0, padding: '3px 0', duration: 2, ease:Power2.linear }, 2.75);

      // Animate the "front scene" images
      container.querySelectorAll("#" + slide.id + " .hero-slide__img-front").forEach((image, k) => {
          timeline.from(image, { z: '+= 500', opacity: 0, duration: 2, ease:Power2.easeOut }, 2.5 + ((k+1)*.4));
      })

      return timeline;
  },
  2: function(slide, container) {
      const timeline = gsap.timeline();

      // Animate the backgrounds
      timeline.from("#" + slide.id + " .hero-slide__image-bg", { filter:'blur(20px)', scale: 2, duration: 2, ease:Power2.easeOut}, 0);
      timeline.from("#" + slide.id + " .hero-slide__image-bg-back", { filter:'blur(20px)', scale: 3.25, duration: 2, ease:Power2.easeOut}, 0);

      timeline.from("#" + slide.id + " .hero-slide__image-pattern", { opacity: 0, duration: 1, ease:Power2.easeOut}, 1);
      timeline.from("#" + slide.id + " .hero-slide__image-pattern", { scale: 1.3, filter:'blur(3px)', duration: 2, ease:Power2.easeOut}, 1);

      timeline.to("#" + slide.id, { backgroundColor: '#000' }, { duration: 2, backgroundColor:'#de162a', ease:Power2.easeOut}, .5);

      const titleEl = container.querySelector("#" + slide.id + " .hero-slide__title")
      timeline.from(titleEl, { z: "+= 500px", opacity: 0, duration: 2, ease:Power2.easeOut}, 1.2);

      timeline.from(titleEl.querySelector(".text__el1"), { duration: .75, opacity: 0, ease:Power2.easeOut}, 1.1);
      timeline.from(titleEl.querySelector(".text__el1"), { duration: 1.5, x: -400, ease:Power2.easeOut}, 1.1);

      timeline.from(titleEl.querySelector(".text__el2"), { duration: .75, opacity: 0, ease:Power2.easeOut}, 1.75);
      timeline.from(titleEl.querySelector(".text__el2"), { duration: 1.5, x: 400, ease:Power2.easeOut}, 1.75);

      // Animate the "front scene" houses images
      container.querySelectorAll("#" + slide.id + " .hero-slide__img--maison").forEach((image, k) => {
          timeline.from(image, { duration: 2, opacity: 0, ease:Power2.easeOut}, 2.25 + ((k+1)*.4));
      })
      
      // Animate the "front scene" box lines
      container.querySelectorAll("#" + slide.id + " .hero-slide__img--line polyline").forEach((image, k) => {
          timeline.from(image, { drawSVG: "0%", duration: 1.4, ease:Power2.easeOut}, 3.5 + ((k+1)*.8));
      })

      // Animate the "front scene" box images
      container.querySelectorAll("#" + slide.id + " .hero-slide__img--box").forEach((image, k) => {
          timeline.from(image, { x: "-= 10", opacity: 0, duration: .8, ease:Power2.easeOut }, 4.3 + ((k+1)*.8));
      })

      return timeline;
  },
  3: function(slide) {
      const timeline = gsap.timeline();
      timeline.pause();

      return timeline;
  }
}