/*!
 * DrawSVGPlugin 3.12.3
 * https://greensock.com
 *
 * @license Copyright 2023, GreenSock. All rights reserved.
 * @author: Jack Doyle, jack@greensock.com
*/

"use strict";
function t() {
    return "undefined" != typeof window;
}
function n() {
    return c || (t() && (c = window.gsap) && c.registerPlugin && c);
}
function r(e) {
    return Math.round(1e4 * e) / 1e4;
}
function i(e) {
    return parseFloat(e) || 0;
}
function s(e, t) {
    var n = i(e);
    return ~e.indexOf("%") ? (n / 100) * t : n;
}
function o(e, t) {
    return i(e.getAttribute(t));
}
function a(e, t, n, r, s, o) {
    return P(Math.pow((i(n) - i(e)) * s, 2) + Math.pow((i(r) - i(t)) * o, 2));
}
function f(e) {
    return console.warn(e);
}
function $(e) {
    return "non-scaling-stroke" === e.getAttribute("vector-effect");
}
function h() {
    return String.fromCharCode.apply(null, arguments);
}
function l(e) {
    if (!(e = g(e)[0])) return 0;
    var t,
        n,
        i,
        s,
        h,
        l,
        u,
        d = e.tagName.toLowerCase(),
        _ = e.style,
        c = 1,
        p = 1;
    $(e) && ((c = P((p = e.getScreenCTM()).a * p.a + p.b * p.b)), (p = P(p.d * p.d + p.c * p.c)));
    try {
        n = e.getBBox();
    } catch (y) {
        f("Some browsers won't measure invisible elements (like display:none or masks inside defs).");
    }
    var w = n || { x: 0, y: 0, width: 0, height: 0 },
        v = w.x,
        k = w.y,
        D = w.width,
        T = w.height;
    if (
        ((n && (D || T)) || !b[d] || ((D = o(e, b[d][0])), (T = o(e, b[d][1])), "rect" !== d && "line" !== d && ((D *= 2), (T *= 2)), "line" === d && ((v = o(e, "x1")), (k = o(e, "y1")), (D = Math.abs(D - v)), (T = Math.abs(T - k)))),
        "path" === d)
    )
        (s = _.strokeDasharray),
            (_.strokeDasharray = "none"),
            (t = e.getTotalLength() || 0),
            r(c) !== r(p) && !x && (x = 1) && f("Warning: <path> length cannot be measured when vector-effect is non-scaling-stroke and the element isn't proportionally scaled."),
            (t *= (c + p) / 2),
            (_.strokeDasharray = s);
    else if ("rect" === d) t = 2 * D * c + 2 * T * p;
    else if ("line" === d) t = a(v, k, v + D, k + T, c, p);
    else if ("polyline" === d || "polygon" === d) for (i = e.getAttribute("points").match(m) || [], "polygon" === d && i.push(i[0], i[1]), t = 0, h = 2; h < i.length; h += 2) t += a(i[h - 2], i[h - 1], i[h], i[h + 1], c, p) || 0;
    else ("circle" !== d && "ellipse" !== d) || (t = Math.PI * (3 * ((l = (D / 2) * c) + (u = (T / 2) * p)) - P((3 * l + u) * (l + 3 * u))));
    return t || 0;
}
function u(e, t) {
    if (!(e = g(e)[0])) return [0, 0];
    t = t || l(e) + 1;
    var n = p.getComputedStyle(e),
        r = n.strokeDasharray || "",
        s = i(n.strokeDashoffset),
        o = r.indexOf(",");
    return o < 0 && (o = r.indexOf(" ")), t < (r = o < 0 ? t : i(r.substr(0, o))) && (r = t), [-s || 0, r - s || 0];
}
function d() {
    t() && ((p = window), (w = c = n()), (g = c.utils.toArray), (v = c.core.getStyleSaver), (k = c.core.reverting || function () {}), (y = -1 !== ((p.navigator || {}).userAgent || "").indexOf("Edge")));
}
var _,
    c,
    g,
    p,
    y,
    w,
    x,
    v,
    k,
    m = /[-+=\.]*\d+[\.e\-\+]*\d*[e\-\+]*\d*/gi,
    b = { rect: ["width", "height"], circle: ["r", "r"], ellipse: ["rx", "ry"], line: ["x2", "y2"] },
    P = Math.sqrt,
    D = (h(103, 114, 101, 101, 110, 115, 111, 99, 107, 46, 99, 111, 109), h(103, 115, 97, 112, 46, 99, 111, 109), "undefined" != typeof window && window.location.host, !0),
    T = {
        version: "3.12.3",
        name: "drawSVG",
        register: function e(t) {
            (c = t), d();
        },
        init: function e(t, n, o) {
            if (!t.getBBox) return !1;
            w || d();
            var a,
                f,
                h,
                _,
                c,
                g,
                x,
                k,
                m,
                b = l(t);
            return (
                (this.styles = v && v(t, "strokeDashoffset,strokeDasharray,strokeMiterlimit")),
                (this.tween = o),
                (this._style = t.style),
                (this._target = t),
                n + "" == "true" ? (n = "0 100%") : n ? -1 === (n + "").indexOf(" ") && (n = "0 " + n) : (n = "0 0"),
                (f = ((_ = n), (c = b), (g = (a = u(t, b))[0]), (k = (m = _.indexOf(" ")) < 0 ? ((x = void 0 !== g ? g + "" : _), _) : ((x = _.substr(0, m)), _.substr(m + 1))), (x = s(x, c)), (k = s(k, c)) < x ? [k, x] : [x, k])),
                (this._length = r(b)),
                (this._dash = r(a[1] - a[0])),
                (this._offset = r(-a[0])),
                (this._dashPT = this.add(this, "_dash", this._dash, r(f[1] - f[0]), 0, 0, 0, 0, 0, 1)),
                (this._offsetPT = this.add(this, "_offset", this._offset, r(-f[0]), 0, 0, 0, 0, 0, 1)),
                y && (h = p.getComputedStyle(t)).strokeLinecap !== h.strokeLinejoin && ((f = i(h.strokeMiterlimit)), this.add(t.style, "strokeMiterlimit", f, f + 0.01)),
                (this._live = $(t) || ~(n + "").indexOf("live")),
                (this._nowrap = ~(n + "").indexOf("nowrap")),
                this._props.push("drawSVG"),
                D
            );
        },
        render: function e(t, n) {
            if (n.tween._time || !k()) {
                var r,
                    i,
                    s,
                    o,
                    a = n._pt,
                    f = n._style;
                if (a) {
                    for (
                        n._live &&
                        (r = l(n._target)) !== n._length &&
                        ((i = r / n._length), (n._length = r), n._offsetPT && ((n._offsetPT.s *= i), (n._offsetPT.c *= i)), n._dashPT ? ((n._dashPT.s *= i), (n._dashPT.c *= i)) : (n._dash *= i));
                        a;

                    )
                        a.r(t, a.d), (a = a._next);
                    (s = n._dash || (t && 1 !== t && 1e-4) || 0),
                        (r = n._length - s + 0.1),
                        (o = n._offset),
                        s && o && s + Math.abs(o % n._length) > n._length - 0.2 && (o += o < 0 ? 0.1 : -0.1) && (r += 0.1),
                        (f.strokeDashoffset = s ? o : o + 0.001),
                        (f.strokeDasharray = r < 0.2 ? "none" : s ? s + "px," + (n._nowrap ? 999999 : r) + "px" : "0px, 999999px");
                }
            } else n.styles.revert();
        },
        getLength: l,
        getPosition: u,
    };

export default T;
