// The CSS will be compiled by Vite and loaded as Wordpress enqueued style asset automaticaly
import '../css/slider.css';

// Gsap
import { gsap } from 'gsap';
import DrawSVGPlugin from './DrawSVGPlugin.js';
gsap.registerPlugin(DrawSVGPlugin);

// Animations
import { continuousAnimations, introAnimations } from './animations.js';

/*!
 * PUI Animated Slider JS 1.0.0
 *
 * @author HUMI Agency, antoni.ferraz@humi-agency.fr
 */
(function(document){

    console.log('Hero slider initialization ⏳');
    const loadStartTime = Date.now();
    
    let currentSlide;
    let slider = null;
    const sliderTimeslines = {};
    let currentSlideTimeline = null;

    let request = null;
    const mouse = { x: 0, y: 0 };
    
    let dx, dy;
    let cx = window.innerWidth / 2;
    let cy = window.innerHeight / 2;
    let degreeRatio = window.innerWidth < 1000 ? .5 : 2;

    let autoCurrentX = 0;
    let autoCurrentY = 0;

    const sectionContainer = document.querySelector('#hero-animated-slider');
    

    // The number of "points" to draw on the auto mouse animation ellipse
    // In practice it allows to define the speed of the animation as each new 
    // animation step is computed on requestAnimationFrame callbacks, thus more 
    // points to draw means more frames and a longer animation
    const autoEllispePointsCount = 1400; 
    // The X and Y radius for the ellipse to be used in the auto mouse animation
    const autoEllipseRadiusY = 150;
    const autoEllipseRadiusX = 600;

    const j = 360/autoEllispePointsCount;
    const degreesOffset = j*3.14/180;
    let currentDegrees = degreesOffset;

    // Make an "automatic mouse animation" by drawing points on an ellipse
    // and adding these x and y positions to the pointer mouse animation computation
    function autoAnimationUpdate() {
        cancelAnimationFrame(request);

        autoCurrentY = autoEllipseRadiusY*Math.sin(currentDegrees);
        autoCurrentX = autoEllipseRadiusX*Math.cos(currentDegrees);

        updateMouseAnimation();
    
        currentDegrees += degreesOffset
        if(currentDegrees > autoEllispePointsCount*degreesOffset) {
            currentDegrees = degreesOffset;
        }

        request = requestAnimationFrame(autoAnimationUpdate);
    }
    
    const updateMouseAnimation = () => {
        dx = mouse.x - cx + autoCurrentX - (autoEllipseRadiusX*2);
        dy = mouse.y - cy + autoCurrentY - (autoEllipseRadiusY*2);

        const animationIndex = currentSlide.dataset.sliderAnimation;
        
        if(animationIndex && continuousAnimations[animationIndex] 
            && typeof continuousAnimations[animationIndex] == 'function') {
            continuousAnimations[animationIndex](
                currentSlide, 
                { 
                    x: (dy / cy), 
                    y: (dx / cx) 
                }, 
                degreeRatio
            )
        }
    }

    // Animate a slide by playing its introduction animation timeline
    const animateSlide = (slide) => {
        if(currentSlideTimeline) {
            currentSlideTimeline.pause();
        }

        currentSlide = slide;
        if(!currentSlide) return;

        mouse.x = window.innerWidth / 2;
        mouse.y = window.innerHeight / 2;

        const previousSlide = document.querySelector('.hero-slide__loaded')
        if(previousSlide) {
            previousSlide.classList.remove('hero-slide__loaded')
        }
        slide.classList.add('hero-slide__loaded')

        if(sliderTimeslines[slide.id]) {
            currentSlideTimeline = sliderTimeslines[slide.id];
            currentSlideTimeline.play(0);
        }
    }

    // Initialize and start the slider by animating the first slide
    const startSlider = function() {
        slider = new Swiper('#hero-animated-slider', {
            speed: 600,
            spaceBetween: 0,
            allowTouchMove: false,
            loop: true,
            pagination: {
                clickable: true,
                enabled: true
            },
            createElements: true,
            autoplay: {
                delay: 12000,
            },
        });

        currentSlide = slider.slides[0];

        // We create the gsap timelines for each slides
        slider.slides.forEach((slide) => {
            const animationIndex = slide.dataset.sliderAnimation;
            if(animationIndex && introAnimations[animationIndex] && typeof introAnimations[animationIndex] == 'function') {
                sliderTimeslines[slide.id] = introAnimations[animationIndex](slide, sectionContainer);
            } else {
                sliderTimeslines[slide.id] = gsap.timeline();
            }
        })

        slider.on('beforeTransitionStart', () => {
            animateSlide(slider.slides[slider.activeIndex])
        })

        setTimeout(() => {
            animateSlide(slider.slides[0])
        }, 1000)
    }

    const onDocumentReady = function () {
        // Once all slide images are loaded, show the slide and start the animation 
        Promise.all(Array.from(document.querySelectorAll('#hero-animated-slider .hero-slide img')).filter(img => !img.complete).map(img => new Promise(resolve => { img.onload = img.onerror = resolve; }))).then(() => {
            const timeToLoadMs = Date.now() - loadStartTime;
            console.log('Hero slider assets loaded in ' +timeToLoadMs+ 'ms. Starting animation 🚀');
            
            document.body.addEventListener('mousemove', (function(event) {
                mouse.x = event.pageX;
                mouse.y = event.pageY;
            
                // cancelAnimationFrame(request);
                // request = requestAnimationFrame(update);	
            }));
            
            window.addEventListener('resize', function() {
                cx = window.innerWidth / 2;
                cy = window.innerHeight / 2;
                degreeRatio = window.innerWidth < 1000 ? .5 : 2;
            });

            startSlider()
            
            autoAnimationUpdate()
        });
    }

    if('ready' == document.readyState) {
        onDocumentReady();
    } else {
        document.addEventListener("DOMContentLoaded", onDocumentReady);
    }
 
})(window.document);